import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  TablePagination,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../examples/Tables/DataTable";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";

import API_URL from "../../../Config/config";

export const columns = [
  { Header: "Version Name", accessor: "version", align: "left" },
  { Header: "Action", accessor: "view", align: "center" },
];

function Versions() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [versionData, setVersionData] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [version, setVersion] = useState("");
  const [csvData, setCsvData] = useState(null);
  const [csvFileName, setCsvFileName] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);

  // Fetch API
  const fetchVersionData = () => {
    axios
      .get(`${API_URL}/version/get-all`)
      .then((response) => {
        setVersionData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching version data:", error);
      });
  };

  useEffect(() => {
    fetchVersionData();
  }, []);

  const handleCreateClick = () => setOpenCreateModal(true);

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    setVersionData([]);
    fetchVersionData();
  };

  const handleUploadClick = () => setOpenUploadModal(true);
  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    fetchVersionData();
  };

  const handleSubmit = () => {
    const newVersion = {
      version: version,
    };

    // axios to create a version
    axios
      .post(`${API_URL}/version/new-version`, newVersion)
      .then((response) => {
        console.log("Response Data:", response);
        setVersion("");
        setOpenCreateModal(false);
        fetchVersionData();
        toast.success("Version created successfully!");
      })
      .catch((error) => {
        console.error("There was an error creating the version:", error);
        toast.error("Error creating version!");
      });
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = versionData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop: (acceptedFiles) => handleUploadCSV(acceptedFiles),
  });

  const handleUploadCSV = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setCsvFileName(file.name);
    const formData = new FormData();
    formData.append("file", file);
    setCsvData(formData);
    setOpenUploadModal(true);
  };

  const handleSubmitCSV = () => {
    if (csvData) {
      // axios to upload CSV File
      axios
        .post(`${API_URL}/version/upload`, csvData)
        .then((response) => {
          toast.success("CSV Data Submitted Successfully!");
          setOpenUploadModal(false);
          setCsvFileName(null);
          fetchVersionData();
        })
        .catch((error) => {
          console.error("Error submitting CSV:", error);
          toast.error("Version already exists,Check the CSV file Data.");
          setCsvFileName(null);
        });
    } else {
      toast.error("No CSV data to upload.");
    }
  };

  // delete api
  const handleDeleteVersion = () => {
    if (selectedVersion) {
      axios
        .delete(`${API_URL}/version/delete/${selectedVersion.id}`)
        .then((response) => {
          toast.success("version deleted successfully!");
          setOpenViewModal(false);
          fetchVersionData();
        })
        .catch((error) => {
          console.error("Error deleting version:", error);
          toast.error("Error deleting version!");
        });
    }
  };

  const handleViewClick = (versionId) => {
    const version = versionData.find((version) => version.id === versionId);
    setSelectedVersion(version);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectedVersion(null);
  };

  return (
    <MDBox pt={3} pb={3} px={3}>
      {/* Header with buttons */}
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "20px" }}
      >
        <MDTypography variant="h4" color="Black" sx={{ flex: 1 }}>
          Versions
        </MDTypography>

        <MDBox display="flex" gap="10px" sx={{ flexShrink: 0 }}>
          <Button
            variant="contained"
            sx={{
              height: "36px",
              padding: "0 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
              color: "#FFFFFF",
              backgroundColor: "#636AE8",
              opacity: 1,
              border: "none",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#4850E4",
              },
              "&:active": {
                backgroundColor: "#2C35E0",
              },
              "&:disabled": {
                opacity: 0.4,
              },
            }}
            onClick={handleCreateClick}
          >
            Create
          </Button>
          <Button
            variant="contained"
            sx={{
              height: "36px",
              padding: "0 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
              color: "#FFFFFF",
              backgroundColor: "#636AE8",
              opacity: 1,
              border: "none",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#4850E4",
              },
              "&:active": {
                backgroundColor: "#2C35E0",
              },
              "&:disabled": {
                opacity: 0.4,
              },
            }}
            onClick={handleUploadClick}
          >
            Upload
          </Button>
        </MDBox>
      </MDBox>

      {/* Data Table */}
      <DataTable
        table={{
          columns: columns,
          rows: paginatedRows.map((version) => ({
            ...version,
            view: (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  color: "black !important",
                  borderColor: "blue !important",
                  "&:hover": {
                    borderColor: "black !important",
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                  },
                }}
                onClick={() => handleViewClick(version.id)}
              >
                Delete
              </Button>
            ),
          })),
        }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
        noEndBorder
      />

      {/* Table Pagination */}
      <TablePagination
        component="div"
        count={versionData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <ToastContainer />

      {/* Modal for Creating New Version */}
      <Dialog open={openCreateModal} onClose={handleCloseCreateModal}>
        <DialogTitle>Create Version</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={version}
            onChange={(e) => setVersion(e.target.value)}
            label="Version Name"
            sx={{ marginBottom: "20px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Uploading CSV File */}
      <Dialog open={openUploadModal} onClose={handleCloseUploadModal}>
        <DialogTitle>Upload CSV File</DialogTitle>
        <DialogContent>
          <div
            {...getRootProps()}
            style={{ border: "2px dashed #636AE8", padding: "20px", textAlign: "center" }}
          >
            <input {...getInputProps()} />
            {!csvFileName ? (
              <MDTypography variant="h6" color="black">
                Drag & Drop a CSV file here, or click to select one
              </MDTypography>
            ) : (
              <MDTypography variant="h6" color="black">
                File: {csvFileName} (Click to change)
              </MDTypography>
            )}
          </div>
          {csvFileName && (
            <div style={{ marginTop: "20px" }}>
              <MDTypography variant="h6" color="black">
                {" "}
                Click &#34;Upload&#34; to save it.
              </MDTypography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitCSV} color="primary">
            Upload
          </Button>
          <Button onClick={handleCloseUploadModal} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Modal for delete version */}
      <Dialog open={openViewModal} onClose={handleCloseViewModal}>
        <DialogTitle>Are you sure you want to delete this product version?</DialogTitle>
        <DialogContent>
          {selectedVersion && (
            <>
              <MDTypography variant="body1"> Version: {selectedVersion.version}</MDTypography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteVersion} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default Versions;
