import React from "react";
import Runs from "../src/layouts/tables";
import Settings from "../src/layouts/Settings";
import Units from "layouts/Units/Units";

// import SignIn from "../src/layouts/authentication/sign-in";
// import SignUp from "../src/layouts/authentication/sign-up";

import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    // key: "runs",
    icon: <Icon fontSize="small">Dashboard</Icon>,
    // route: "/runs",  
    // component: <Runs />,
  },
  {
    type: "collapse",
    name: "Runs",
    key: "runs",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/runs",  
    component: <Runs />,
  },
    {
    type: "collapse",
    name: "Units",
    key: "units",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/units",
    component: <Units />,
  },

  {
    type: "collapse",
    name: "Settings",
    key: "Settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: <Settings />,
  },
  {
    type: "collapse",
    name: "Data Loader",
    // key: "sign-in",
    icon: <Icon fontSize="small">assignment</Icon>,
    // route: "/authentication/sign-in",
    // component: <SignIn />,
  },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },

];

export default routes;
