import React from "react";

// @mui material components
import Icon from "@mui/material/Icon";

// Material UI & React React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material UI & React React base styles
import typography from "../../assets/theme/base/typography";

function Footer() {
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      py={2}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        {/* <MDTypography variant="body2" color="text" fontSize={size.sm}>
          Custom text here
        </MDTypography>
        <MDBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}>
          <Icon color="inherit" fontSize="inherit">
            favorite
          </Icon>
        </MDBox>
        <MDTypography variant="body2" color="text" fontSize={size.sm}>
          by Your Company
        </MDTypography> */}
      </MDBox>
    </MDBox>
  );
}

export default Footer;
