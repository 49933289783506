import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { Tabs, Tab } from "@mui/material"; 
import Sensors from "./Sensors/Sensors";  
import Versions from "./ProductVersion/ProductVersion";
import Clients from "./Clients/Clients";
import Groups from "./Groups/Groups";

function Settings() {
  const [tabValue, setTabValue] = useState(0);  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);  
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <MDTypography variant="h4" color="Black" sx={{ marginLeft: "25px", marginTop: "10px" }}>
                      Settings
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                {/* Tabs for navigation */}
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                  sx={{
                    marginBottom: "20px",
                    ".Mui-selected": {
                      backgroundColor: "#636AE8",  // Highlight color for the active tab
                      color: "#fff",  
                    },
                    ".MuiTab-root": {
                      fontSize: "16px",  
                      fontWeight: "600",  
                    },
                  }}
                >
                  <Tab label="Sensors" />
                  <Tab label="Product Version" />
                  <Tab label="Clients" />
                  <Tab label="Groups" />
                </Tabs>

                {/* Render content based on selected tab */}
                {tabValue === 0 && <Sensors />}  
                {tabValue === 1 && <Versions />}     
                {tabValue === 2 && <Clients />}     
                {tabValue === 3 && <Groups />}     
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Settings;
