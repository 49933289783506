import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";

import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

import BasicLayout from "../components/BasicLayout/index";
import Asentriaimg from "../../../assets/images/Asentria_home.jpg";

import { ToastContainer, toast } from "react-toastify";

import API_URL from "../../../Config/config";

function Basic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { email, password };

    try {
      const response = await axios.post(`${API_URL}/user/sign-in`, data);

      if (response.status === 200) {
        setErrorMessage("");
        console.log("Login successful");
        toast.success("Login successful!");

        // Store JWT token in localStorage
        localStorage.setItem("token", response.data.token);
        navigate("/runs");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An error occurred during login.");
      }
    }
  };

  return (
    <BasicLayout image={Asentriaimg}>
      <Card sx={{ maxWidth: 400, mx: "auto", p: 4, borderRadius: 2 }}>
        <MDBox textAlign="center" mb={2}>
          <MDTypography variant="h4" fontWeight="medium" color="black">
            Sign in
          </MDTypography>
        </MDBox>

        <MDBox component="form" role="form" onSubmit={handleSubmit}>
          <MDBox mb={3}>
            <MDInput
              type="email"
              label="Email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ padding: "10px", fontSize: "16px" }}
            />
          </MDBox>

          <MDBox mb={3}>
            <MDInput
              type="password"
              label="Password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ padding: "10px", fontSize: "16px" }}
            />
          </MDBox>

          {errorMessage && (
            <MDTypography variant="body2" color="error" textAlign="center" mt={1}>
              {errorMessage}
            </MDTypography>
          )}

          <Grid container alignItems="center" justifyContent="space-between" mb={3}>
            <Grid item>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </Grid>
          </Grid>

          <MDBox mt={2} mb={2}>
            <MDButton variant="gradient" color="info" fullWidth type="submit">
              Sign in
            </MDButton>
          </MDBox>

          <MDBox textAlign="center">
            <MDTypography variant="button" color="text">
              Don&apos;t have an account?{" "}
              <MDTypography
                component={Link}
                to="/authentication/sign-up"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign up
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
      <ToastContainer />
    </BasicLayout>
  );
}

export default Basic;
