import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../src/assets/theme";
import routes from "./routes";
import { useMaterialUIController } from "../src/context/index";
import Sidenav from "../src/examples/Sidenav";
import brandWhite from "../src/assets/images/android-icon.png";

import SignIn from "../src/layouts/authentication/sign-in";
import SignUp from "../src/layouts/authentication/sign-up";
import Runs from "../src/layouts/tables/index";
import RunHistory from "./layouts/tables/data/RunHistory";
import UnitHistory from "./layouts/tables/data/UnitHistory";
import CreateRun from "./layouts/tables/CreateRun/Create";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { layout } = controller;
  const { pathname } = useLocation();

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.component) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!pathname.startsWith("/authentication/sign-in") && !pathname.startsWith("/authentication/sign-up") && (
        <Sidenav brand={brandWhite} brandName="SB Simulator" routes={routes} />
      )}

      <Routes>
        {getRoutes(routes)}

        {/* Authentication routes */}
        <Route path="/authentication/sign-up" element={<SignUp />} />
        <Route path="/authentication/sign-in" element={<SignIn />} />

        {/* routes */}
        <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
        <Route path="/runs" element={<Runs />} />
        <Route path="/runs/create-run" element={<CreateRun />} />
        <Route path="/runs/runs-history/:runId" element={<RunHistory />} />
        <Route path="/runs/runs-history/:runId/unit-history" element={<UnitHistory />} />

        <Route path="*" element={<Navigate to="/settings" />} />

        <Route path="*" element={<Navigate to="/units" />} />


      </Routes>
    </ThemeProvider>
  );
}
