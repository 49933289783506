import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";

import MDBox from "../../../components/MDBox";

import Breadcrumbs from "../../../examples/Breadcrumbs";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from "../../../examples/Navbars/DashboardNavbar/styles";

import { useMaterialUIController, setTransparentNavbar } from "../../../context/index";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar } = controller;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();
  const route = location.pathname.split("/").slice(1);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle logout functionality
  const handleLogout = () => {
    // Clear any authentication tokens or session data here (e.g., localStorage or cookies)
    // localStorage.removeItem("authToken"); // Example: Removing a token from localStorage

    navigate("/authentication/sign-in");
    handleClose();
  };

  // Handle navbar type based on scroll
  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light ? white.main : dark.main;
      if (transparentNavbar && !light) {
        colorValue = rgba(text.main, 0.6);
      }
      return colorValue;
    },
  });

  const generateBreadcrumbs = () => {
    const breadcrumbs = route.map((crumb, index) => {
      const path = `/${route.slice(0, index + 1).join("/")}`;
      return (
        <Link key={index} to={path}>
          {crumb}
        </Link>
      );
    });

    return breadcrumbs;
  };

  // Popover open status
  const open = Boolean(anchorEl);
  const id = open ? "account-popover" : undefined;

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1] || "Runs"}
            route={generateBreadcrumbs()}
            light={light}
          />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton sx={navbarIconButton} size="small" disableRipple onClick={handleClick}>
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>

              {/* Popover with logout button */}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Popover>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
