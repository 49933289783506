import React, { useState, useEffect, useRef } from "react";
import { Grid, Card, TablePagination, Button, Checkbox } from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { toast, ToastContainer } from "react-toastify";

import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DataTable from "../../examples/Tables/DataTable";

import API_URL from "../../Config/config";

function Units() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [units, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  // Ref for CSVLink
  const csvLinkRef = useRef();

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/units/unit-data`);
      let fetchedData = Array.isArray(response.data) ? response.data : [];

      fetchedData = fetchedData.map((unit, index) => ({
        ...unit,
        unit_id: unit.unit_id ?? `temp-${index}`,
      }));
      setData(fetchedData);
      setTotalRecords(fetchedData.length);
    } catch (error) {
      console.error("Error fetching units", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowSelection = (event, rowId) => {
    if (!rowId) {
      console.error("Invalid row ID:", rowId);
      return;
    }

    setSelectedRows((prevSelected) =>
      event.target.checked ? [...prevSelected, rowId] : prevSelected.filter((id) => id !== rowId)
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(units.map((unit) => unit.unit_id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleSendDataToAPI = async () => {
    const selectedData = units.filter((unit) => selectedRows.includes(unit.unit_id));

    if (selectedData.length > 0) {
      try {
        const response = await axios.post(`${API_URL}/units/save-units`, selectedData);
        console.log("Data sent successfully:", response.data);
        toast.success("Data sent successfully!");
      } catch (error) {
        console.error("Error sending data to API", error);
        toast.error("Error sending data to API");
      }
    } else {
      toast.warn("No rows selected.");
    }
  };

  const handleExportCSV = async () => {
    if (selectedRows.length === 0) {
      toast.warn("No rows selected for export.");
      return;
    }

    try {
      const selectedIdsQuery = selectedRows.join(",");

      const response = await axios.get(
        `${API_URL}/units/export-csv?selectedIds=${selectedIdsQuery}`,
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Sb-Simulator-Units.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("CSV file downloaded successfully!");
      setSelectedRows([]);
    } catch (error) {
      console.error("Error exporting CSV:", error);
      toast.error("Failed to export CSV.");
    }
  };
  const handleViewClick = (rowData) => {
    console.log("View data: ", rowData);
  };

  const columns = [
    {
      Header: (
        <Checkbox
          indeterminate={selectedRows.length > 0 && selectedRows.length < units.length}
          checked={selectedRows.length === units.length}
          onChange={handleSelectAll}
        />
      ),
      accessor: "select",
      Cell: ({ row }) => {
        return (
          <Checkbox
            checked={selectedRows.includes(row.original.unit_id)}
            onChange={(event) => handleRowSelection(event, row.original.unit_id)}
          />
        );
      },
      align: "center",
    },
    { Header: "Site Name", accessor: "site_name", align: "left" },
    { Header: "Company Code", accessor: "company_code", align: "left" },
    { Header: "IP Address", accessor: "IP_Address", align: "left" },
    { Header: "Site Group", accessor: "group_name", align: "center" },
    { Header: "Latitude", accessor: "latitude", align: "center" },
    { Header: "Longitude", accessor: "longitude", align: "center" },
    { Header: "Device Id", accessor: "deviceId", align: "center" },
    { Header: "Client Id", accessor: "clientId", align: "center" },
    {
      Header: "Action",
      accessor: "view",
      Cell: ({ row }) => (
        <Button
          variant="outlined"
          size="small"
          sx={{
            color: 'black !important',  
            borderColor: 'blue !important',  
            '&:hover': {
              borderColor: 'black !important',  
              backgroundColor: 'rgba(0, 0, 0, 0.08)', 
            },
          }}
          // onClick={() => handleViewClick(row.original)} 
        >
          Start
        </Button>
      ),
      align: "center",
    }
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography
                  variant="h5"
                  color="Black"
                  sx={{ marginLeft: "25px", marginTop: "10px" }}
                >
                  New Units Ready to Process
                </MDTypography>
                <MDBox
                  display="flex"
                  gap="10px"
                  sx={{ flexShrink: 0, marginTop: "15px", marginRight: "15px" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      height: "36px",
                      padding: "0 12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: 400,
                      color: "#FFFFFF",
                      backgroundColor: "#636AE8",
                      opacity: 1,
                      border: "none",
                      borderRadius: "6px",
                      "&:hover": {
                        backgroundColor: "#4850E4",
                      },
                      "&:active": {
                        backgroundColor: "#2C35E0",
                      },
                      "&:disabled": {
                        opacity: 0.4,
                      },
                    }}
                    onClick={handleSendDataToAPI}
                  >
                    Publish On Swarn
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      height: "36px",
                      padding: "0 12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: 400,
                      color: "#FFFFFF",
                      backgroundColor: "#636AE8",
                      opacity: 1,
                      border: "none",
                      borderRadius: "6px",
                      "&:hover": {
                        backgroundColor: "#4850E4",
                      },
                      "&:active": {
                        backgroundColor: "#2C35E0",
                      },
                      "&:disabled": {
                        opacity: 0.4,
                      },
                    }}
                    onClick={handleExportCSV}
                  >
                    Export CSV
                  </Button>

                  <CSVLink
                    ref={csvLinkRef}
                    data={units.filter((unit) => selectedRows.includes(unit.unit_id))}
                    filename={"selected_units.csv"}
                    className="no-style-link"
                  />
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: columns,
                    rows: units.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

Units.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      unit_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default Units;
