import React, { useState, useEffect } from "react";

import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  TablePagination,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../examples/Tables/DataTable";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";

import API_URL from "../../../Config/config";

export const columns = [
  { Header: "Client Name", accessor: "client_name", align: "left" },
  { Header: "Company Code", accessor: "company_code", align: "left" },
  {
    Header: "Status",
    accessor: "status",
    align: "center",
    Cell: ({ cell: { value } }) => getStatusLabel(value),
  },
  { Header: "Action", accessor: "view", align: "center" },
];

const statusLabelMapping = {
  1: "Active",
  0: "Inactive",
};

const getStatusLabel = (statusValue) => {
  return statusLabelMapping[statusValue] || "Unknown";
};

// Status options
const statusOptions = [
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

function Clients() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [clientsData, setClientsData] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [client_name, setClientName] = useState("");
  const [company_code, setCompanyCode] = useState("");
  const [status, setStatus] = useState({ value: "1", label: "Active" });
  const [csvData, setCsvData] = useState(null);
  const [csvFileName, setCsvFileName] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);

  // Fetch Clients Data from API
  const fetchClientsData = () => {
    axios
      .get(`${API_URL}/client`)
      .then((response) => {
        setClientsData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the client data:", error);
      });
  };

  useEffect(() => {
    fetchClientsData();
  }, []);

  const handleCreateClick = () => setOpenCreateModal(true);

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    setClientName("");
    setCompanyCode("");
    setStatus({ value: "1", label: "Active" });
    fetchClientsData();
  };

  const handleUploadClick = () => setOpenUploadModal(true);
  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    fetchClientsData();
  };

  const handleSubmit = () => {
    const newClient = {
      client_name: client_name,
      company_code: company_code,
      status: status.value,
    };

    // axios to create a client
    axios
      .post(`${API_URL}/client/new-client`, newClient)
      .then((response) => {
        console.log("New Client Created:", response.data);
        setClientName("");
        setCompanyCode("");
        setStatus({ value: "1", label: "Active" });
        setOpenCreateModal(false);
        fetchClientsData();
        toast.success("Client created successfully!");
      })
      .catch((error) => {
        toast.error("Client already exists.");
        console.error("There was an error creating the client:", error);
      });
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = clientsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop: (acceptedFiles) => handleUploadCSV(acceptedFiles),
  });

  const handleUploadCSV = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setCsvFileName(file.name);
    const formData = new FormData();
    formData.append("file", file);
    setCsvData(formData);
    setOpenUploadModal(true);
  };

  const handleSubmitCSV = () => {
    if (csvData) {
      // axios to upload CSV File
      axios
        .post(`${API_URL}/client/upload`, csvData)
        .then((response) => {
          toast.success("CSV Data Submitted Successfully!");
          setOpenUploadModal(false);
          setCsvFileName(null);
          fetchClientsData();
        })
        .catch((error) => {
          console.error("Error submitting CSV:", error);
          toast.error("Client already exists,Check the CSV file Data.");
          setCsvFileName(null);
        });
    } else {
      toast.error("No CSV data to upload.");
    }
  };

  // delete api

  const handleDeleteClient = () => {
    if (selectedClient) {
      axios
        .delete(`${API_URL}/client/delete/${selectedClient.id}`)
        .then((response) => {
          toast.success("client deleted successfully!");
          setOpenViewModal(false);
          fetchClientsData();
        })
        .catch((error) => {
          console.error("Error deleting client:", error);
          // toast.error("Error deleting client!");
        });
    }
  };

  const handleViewClick = (clientId) => {
    const client = clientsData.find((client) => client.id === clientId);
    setSelectedClient(client);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectedClient(null);
  };

  return (
    <MDBox pt={3} pb={3} px={3}>
      {/* Header with buttons */}
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "20px" }}
      >
        <MDTypography variant="h4" color="Black" sx={{ flex: 1 }}>
          Clients
        </MDTypography>

        <MDBox display="flex" gap="10px" sx={{ flexShrink: 0 }}>
          <Button
            variant="contained"
            sx={{
              height: "36px",
              padding: "0 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
              color: "#FFFFFF",
              backgroundColor: "#636AE8",
              opacity: 1,
              border: "none",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#4850E4",
              },
              "&:active": {
                backgroundColor: "#2C35E0",
              },
              "&:disabled": {
                opacity: 0.4,
              },
            }}
            onClick={handleCreateClick}
          >
            Create
          </Button>
          <Button
            variant="contained"
            sx={{
              height: "36px",
              padding: "0 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
              color: "#FFFFFF",
              backgroundColor: "#636AE8",
              opacity: 1,
              border: "none",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#4850E4",
              },
              "&:active": {
                backgroundColor: "#2C35E0",
              },
              "&:disabled": {
                opacity: 0.4,
              },
            }}
            onClick={handleUploadClick}
          >
            Upload
          </Button>
        </MDBox>
      </MDBox>

      {/* Data Table */}
      <DataTable
        table={{
          columns: columns,
          rows: paginatedRows.map((client) => ({
            ...client,
            view: (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  color: "black !important",
                  borderColor: "blue !important",
                  "&:hover": {
                    borderColor: "black !important",
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                  },
                }}
                onClick={() => handleViewClick(client.id)}
              >
                Delete
              </Button>
            ),
          })),
        }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
        noEndBorder
      />

      {/* Table Pagination */}
      <TablePagination
        component="div"
        count={clientsData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <ToastContainer />
      {/* Modal for Creating New Client */}

      <Dialog open={openCreateModal} onClose={handleCloseCreateModal}>
        <DialogTitle>Create Client</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={client_name}
            onChange={(e) => setClientName(e.target.value)}
            label="Client Name"
            sx={{ marginBottom: "20px" }}
          />
          <TextField
            fullWidth
            value={company_code}
            onChange={(e) => setCompanyCode(e.target.value)}
            label="Company Code"
            sx={{ marginBottom: "20px" }}
          />
          <FormControl fullWidth sx={{ marginBottom: "40px", minWidth: "150px" }}>
            <Select
              value={status.value}
              onChange={(e) =>
                setStatus(statusOptions.find((option) => option.value === e.target.value))
              }
              label="Status"
              defaultValue="1"
              sx={{
                height: "50px",
                fontSize: "1rem",
              }}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Uploading CSV File */}
      <Dialog open={openUploadModal} onClose={handleCloseUploadModal}>
        <DialogTitle>Upload CSV File</DialogTitle>
        <DialogContent>
          <div
            {...getRootProps()}
            style={{ border: "2px dashed #636AE8", padding: "20px", textAlign: "center" }}
          >
            <input {...getInputProps()} />
            {!csvFileName ? (
              <MDTypography variant="h6" color="black">
                Drag & Drop a CSV file here, or click to select one
              </MDTypography>
            ) : (
              <MDTypography variant="h6" color="black">
                File: {csvFileName} (Click to change)
              </MDTypography>
            )}
          </div>

          {csvFileName && (
            <div style={{ marginTop: "20px" }}>
              <MDTypography variant="h6" color="black">
                {" "}
                Click &#34;Upload&#34; to save it.
              </MDTypography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitCSV} color="primary">
            Upload
          </Button>
          <Button onClick={handleCloseUploadModal} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for delete version */}
      <Dialog open={openViewModal} onClose={handleCloseViewModal}>
        <DialogTitle>Are you sure you want to delete this Client?</DialogTitle>
        <DialogContent>
          {selectedClient && (
            <>
              <MDTypography variant="body1"> Client: {selectedClient.client_name}</MDTypography>
              <MDTypography variant="body1">
                {" "}
                Company Code: {selectedClient.company_code}
              </MDTypography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteClient} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default Clients;
