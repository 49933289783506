import React, { useState } from "react";
import { Grid, Card, IconButton, Modal, Box, Typography, CircularProgress } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import DataTable from "../../../examples/Tables/DataTable";

const columns = [
  { Header: "Site Name", accessor: "site_name", align: "left" },
  { Header: "Stage", accessor: "stage", align: "left" },
  { Header: "Results", accessor: "results", align: "center" },
  { Header: "Run Date", accessor: "run_date", align: "center" },
  { Header: "Status", accessor: "status", align: "center" },
];

const dummyData = [
  {
    site_name: "SBUnit1",
    stage: "Ping Test",
    results: "Success",
    run_date: "Feb 17, 2025",
    status: "Completed",
  },
  {
    site_name: "SBUnit1",
    stage: "Auth Test",
    results: "Failed",
    run_date: "Feb 17, 2025",
    status: "Pending",
  },
  {
    site_name: "SBUnit1",
    stage: "Auth Test",
    results: "Success",
    run_date: "Feb 17, 2025",
    status: "Completed",
  },
  {
    site_name: "SBUnit1",
    stage: "Firmware Test",
    results: "Success",
    run_date: "Feb 17, 2025",
    status: "Completed",
  },
  {
    site_name: "SBUnit1",
    stage: "Sensor Test",
    results: "Success",
    run_date: "Feb 17, 2025",
    status: "Completed",
  },
];

function UnitHistory() {
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getColorForResult = (result) => {
    return result === "Success" ? "green" : "red";
  };

  const getColorForStatus = (status) => {
    return status === "Completed" ? "green" : "orange";
  };

  const handleInfoClick = async (result) => {
    if (result === "Failed") {
      setIsLoading(true);
      setOpenModal(true);
      try {
        const dummyResponse = {
          message:
            "The test failed due to an unexpected timeout. Please check your connection and try again.",
        };
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setMessage(
          dummyResponse.message || "This test has failed. Please check the logs for details."
        );
      } catch (error) {
        setMessage("Failed to load details.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <MDTypography
                  variant="h6"
                  color="Black"
                  sx={{ marginLeft: "25px", marginTop: "10px" }}
                >
                  Unit History
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: columns,
                    rows: dummyData.map((row) => ({
                      ...row,
                      results: (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100px",
                            color: getColorForResult(row.results),
                          }}
                        >
                          <span style={{ marginRight: "5px" }}>{row.results}</span>
                          {row.results === "Failed" && (
                            <IconButton
                              sx={{ padding: 0 }}
                              onClick={() => handleInfoClick(row.results)}
                            >
                              <InfoIcon />
                            </IconButton>
                          )}
                        </div>
                      ),
                      status: (
                        <span style={{ color: getColorForStatus(row.status), fontWeight: "bold" }}>
                          {row.status}
                        </span>
                      ),
                    })),
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#ffffff",
            padding: 4,
            borderRadius: 2,
            boxShadow: 24,
            width: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography id="modal-title" variant="h6">
            Information
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2, textAlign: "center" }}>
            {isLoading ? <CircularProgress /> : message}
          </Typography>
          <button
            onClick={handleCloseModal}
            style={{
              marginTop: "20px",
              padding: "10px",
              backgroundColor: "#1976d2",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              width: "100%",
            }}
            disabled={isLoading}
          >
            Close
          </button>
        </Box>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default UnitHistory;
