import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DataTable from "../../examples/Tables/DataTable";
import { Link } from "react-router-dom";
import {
  Button,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import API_URL from "../../Config/config";

// Fetch run details
const fetchRunDetails = async (runId) => {
  try {
    const response = await axios.get(`${API_URL}/runs/get-run-details/${runId}`);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

const renderStatusBadge = (status) => {
  switch (status) {
    case 3:
      return <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />;
    case 4:
      return <MDBadge badgeContent="Inactive" color="error" variant="gradient" size="sm" />;
    case 1:
      return <MDBadge badgeContent="Pending" color="warning" variant="gradient" size="sm" />;
    case 2:
      return <MDBadge badgeContent="In-progress" color="info" variant="gradient" size="sm" />;
    default:
      return <MDBadge badgeContent="Unknown" color="dark" variant="gradient" size="sm" />;
  }
};

function Runs() {
  const [runsData, setRunsData] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [runDetails, setRunDetails] = useState(null);

  // List Api Axios
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(`${API_URL}/runs/get-all-runs`);
        setRunsData(Array.isArray(response.data) ? response.data : []); 
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
  
    getData();
  }, []);
  

  const columns = [
    { Header: "Run Name", accessor: "run_name", align: "left" },
    { Header: "No:Of Sites", accessor: "total_sites", align: "left" },
    { Header: "Client", accessor: "client_name", align: "center" },
    { Header: "Run Date", accessor: "createdAt", align: "center" },
    { Header: "Status", accessor: "status", align: "center" },
    { Header: "View", accessor: "view", align: "center" },
  ];

  const rows = runsData.map((run) => ({
    run_name: (
      <MDTypography
        component={Link}
        to={`/runs/runs-history/${run.run}`}
        variant="caption"
        color="text"
        fontWeight="medium"
        sx={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
      >
        {run.run_name}
      </MDTypography>
    ),
    status: <MDBox ml={-1}>{renderStatusBadge(run.status)}</MDBox>,
    total_sites: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {run.total_sites}
      </MDTypography>
    ),
    client_name: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {run.client_name}
      </MDTypography>
    ),
    createdAt: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {moment(run.createdAt).format("MMM D, YYYY HH:mm")}
      </MDTypography>
    ),
    view: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="primary"
        fontWeight="medium"
        onClick={(e) => {
          e.preventDefault();
          handleOpenModal(run.run);
        }}
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
          color: "blue",
          "&:hover": {
            color: "#0056b3",
          },
        }}
      >
        View
      </MDTypography>
    ),
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = async (runId) => {
    try {
      const details = await fetchRunDetails(runId);
      setRunDetails(details);
      setIsModalOpen(true);
    } catch (err) {
      console.error("Error fetching run details:", err);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRunDetails(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <MDTypography
                      variant="h6"
                      color="Black"
                      sx={{ marginLeft: "25px", marginTop: "10px" }}
                    >
                      Runs
                    </MDTypography>
                  </Grid>
                  <Grid item xs={3} container justifyContent="flex-end">
                    <Link to="/runs/create-run" style={{ textDecoration: "none" }}>
                      <Button
                        variant="contained"
                        sx={{
                          position: "absolute",
                          top: "15px",
                          left: "1000px",
                          height: "36px",
                          padding: "0 15px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          lineHeight: "22px",
                          fontWeight: 400,
                          color: "#FFFFFF",
                          backgroundColor: "#636AE8",
                          opacity: 1,
                          border: "none",
                          borderRadius: "6px",
                          "&:hover": { backgroundColor: "#4850E4" },
                          "&:active": { backgroundColor: "#2C35E0" },
                          "&:disabled": { opacity: 0.4 },
                        }}
                      >
                        Create a Run
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: runsData.length > 0 ? rows : [{ run_name: "No data available" }],
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
              <TablePagination
                component="div"
                count={runsData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Modal for Run Details */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Run Details</DialogTitle>
        <DialogContent>
          {runDetails ? (
            <>
              {/* General Information */}
              <Grid container spacing={2}>
                {/* First Column */}
                <Grid item xs={6}>
                  <MDTypography variant="body1">
                    <strong>Run Name:</strong> {runDetails.run_name}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Client:</strong> {runDetails.Client.client_name}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Total Sites:</strong>{" "}
                    <a
                      href={`/runs/runs-history/${runDetails.id}/unit-history`}
                      style={{ textDecoration: "none", color: "#1976D2" }}
                    >
                      {runDetails.total_sites}
                    </a>
                  </MDTypography>

                  <MDTypography variant="body1">
                    <strong>Product Version:</strong> {runDetails.ProductVersion.version}
                  </MDTypography>
                </Grid>

                {/* Second Column */}
                <Grid item xs={6}>
                  <MDTypography variant="body1">
                    <strong>Country ID:</strong> {runDetails.Country.name}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Created At:</strong>{" "}
                    {moment(runDetails.createdAt).format("MMM D, YYYY HH:mm")}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Status:</strong> {renderStatusBadge(runDetails.status)}
                  </MDTypography>
                </Grid>
              </Grid>

              <MDTypography variant="h6" sx={{ mt: 3, mb: 2, textDecoration: "underline" }}>
                Test Details
              </MDTypography>

              {/* Test & Count Details */}
              <Grid container spacing={2}>
                {/* First Column */}
                <Grid item xs={6}>
                  <MDTypography variant="body1">
                    <strong>Pre-Commission Test:</strong>{" "}
                    {runDetails.pre_commission_test ? "Yes" : "No"}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Ping Test:</strong> {runDetails.ping_test ? "Yes" : "No"}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Auth Test:</strong> {runDetails.auth_test ? "Yes" : "No"}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Firmware Version Test:</strong>{" "}
                    {runDetails.firmware_version_test ? "Yes" : "No"}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Sensor Test:</strong> {runDetails.sensor_test ? "Yes" : "No"}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Ping MQTT:</strong> {runDetails.ping_mqtt ? "Yes" : "No"}
                  </MDTypography>

                  <MDTypography variant="body1">
                    <strong>LWT:</strong> {runDetails.LWT ? "Yes" : "No"}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>LWT Count:</strong> {runDetails.LWT_count}
                  </MDTypography>
                </Grid>

                {/* Second Column (Counts) */}
                <Grid item xs={6}>
                  <MDTypography variant="body1">
                    <strong>Telemetry Test:</strong> {runDetails.telementry_test ? "Yes" : "No"}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Telemetry Count:</strong> {runDetails.telementry_count}
                  </MDTypography>

                  <MDTypography variant="body1">
                    <strong>Alarm Test:</strong> {runDetails.alarm_test ? "Yes" : "No"}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Alarm Count:</strong> {runDetails.alarm_count}
                  </MDTypography>

                  <MDTypography variant="body1">
                    <strong>Message Delay:</strong> {runDetails.message_delay ? "Yes" : "No"}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Message Delay Count:</strong> {runDetails.message_delay_count}
                  </MDTypography>

                  <MDTypography variant="body1">
                    <strong>Total Sensors:</strong> {runDetails.total_sensors ? "Yes" : "No"}
                  </MDTypography>
                  <MDTypography variant="body1">
                    <strong>Total Sensors Per Unit:</strong> {runDetails.total_sensors_per_unit}
                  </MDTypography>
                </Grid>
              </Grid>
            </>
          ) : (
            <MDTypography variant="body1">Loading...</MDTypography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default Runs;
