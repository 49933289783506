const ENV = process.env.REACT_APP_ENV || "development";

const CONFIG = {
  development: {
    API_URL: "http://localhost:8000/api/sb-simulator",
  },
  staging: {
    API_URL: process.env.REACT_APP_API_URL || "/api/sb-simulator",
  }
};

const API_URL = CONFIG[ENV]?.API_URL || CONFIG.development.API_URL;
console.log("API URL:", API_URL);

export default API_URL;
