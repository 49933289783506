import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  TablePagination,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../examples/Tables/DataTable";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";

import API_URL from "../../../Config/config";

export const columns = [
  { Header: "Sensor Name", accessor: "name", align: "left" },
  { Header: "Serial Number", accessor: "serialNo", align: "center" },
  { Header: "Group", accessor: "group_name", align: "center" },
  {
    Header: "Status",
    accessor: "status",
    align: "center",
    Cell: ({ cell: { value } }) => getStatusLabel(value),
  },
  { Header: "Action", accessor: "view", align: "center" },
];

const statusLabelMapping = {
  1: "Active",
  0: "Inactive",
};

const getStatusLabel = (statusValue) => {
  return statusLabelMapping[statusValue] || "Unknown";
};

// Status options
const statusOptions = [
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

function Sensors() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sensorData, setsensorData] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [name, setname] = useState("");
  const [serialNo, setserialNo] = useState("");
  const [group, setGroup] = useState("");
  const [status, setStatus] = useState({ value: "1", label: "Active" });
  const [csvData, setCsvData] = useState(null);
  const [csvFileName, setCsvFileName] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState(null);

  // Fetch API for sensors
  const fetchsensorData = () => {
    axios
      .get(`${API_URL}/sensor/get-all`)
      .then((response) => {
        setsensorData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching sensor data:", error);
      });
  };

  // Fetch API for groups
  const fetchGroupOptions = () => {
    axios
      .get(`${API_URL}/site-groups/get-all-groups`)
      .then((response) => {
        setGroupOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching group data:", error);
      });
  };

  useEffect(() => {
    fetchsensorData();
    fetchGroupOptions();
  }, []);

  const handleCreateClick = () => setOpenCreateModal(true);
  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    setname("");
    setserialNo("");
    setGroup("");
    setStatus({ value: "1", label: "Active" });
    fetchsensorData();
  };

  const handleUploadClick = () => setOpenUploadModal(true);
  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    setStatus({ value: "1", label: "Active" });
    fetchsensorData();
  };

  const handleSubmit = () => {
    const newsensor = {
      name: name,
      serialNo: serialNo,
      group_id: group,
      status: status.value,
    };

    // axios to create a sensor
    axios
      .post(`${API_URL}/sensor/new-sensor`, newsensor)
      .then((response) => {
        console.log("Response Data:", response);
        setname();
        setserialNo();
        setGroup();
        setStatus({ value: "1", label: "Active" });
        setOpenCreateModal(false);
        fetchsensorData();
        toast.success("Sensor created successfully!");
      })
      .catch((error) => {
        console.error("There was an error creating the sensor:", error);
        toast.error("Error creating sensor!");
      });
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = sensorData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop: (acceptedFiles) => handleUploadCSV(acceptedFiles),
  });

  const handleUploadCSV = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setCsvFileName(file.name);
    const formData = new FormData();
    formData.append("file", file);
    setCsvData(formData);
    setOpenUploadModal(true);
  };

  const handleSubmitCSV = () => {
    if (csvData) {
      // axios to upload CSV File
      axios
        .post(`${API_URL}/sensor/upload`, csvData)
        .then((response) => {
          toast.success("CSV Data Submitted Successfully!");
          setOpenUploadModal(false);
          setCsvFileName(null);
          fetchsensorData();
        })
        .catch((error) => {
          console.error("Error submitting CSV:", error);
          toast.error("Sensors already exist. Check the CSV file data.");
          setCsvFileName(null);
        });
    } else {
      toast.error("No CSV data to upload.");
    }
  };

  // delete api
  const handleDeleteSensor = () => {
    if (selectedSensor) {
      axios
        .delete(`${API_URL}/sensor/delete/${selectedSensor.id}`)
        .then((response) => {
          toast.success("Sensor deleted successfully!");
          setOpenViewModal(false);
          fetchsensorData();
        })
        .catch((error) => {
          console.error("Error deleting sensor:", error);
          toast.error("Error deleting sensor!");
        });
    }
  };

  const handleViewClick = (sensorId) => {
    const sensor = sensorData.find((sensor) => sensor.id === sensorId);
    setSelectedSensor(sensor);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectedSensor(null);
  };

  return (
    <MDBox pt={3} pb={3} px={3}>
      {/* Header with buttons */}
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "20px" }}
      >
        <MDTypography variant="h4" color="Black" sx={{ flex: 1 }}>
          Sensors
        </MDTypography>

        <MDBox display="flex" gap="10px" sx={{ flexShrink: 0 }}>
          <Button
            variant="contained"
            sx={{
              height: "36px",
              padding: "0 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
              color: "#FFFFFF",
              backgroundColor: "#636AE8",
              opacity: 1,
              border: "none",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#4850E4",
              },
              "&:active": {
                backgroundColor: "#2C35E0",
              },
              "&:disabled": {
                opacity: 0.4,
              },
            }}
            onClick={handleCreateClick}
          >
            Create
          </Button>
          <Button
            variant="contained"
            sx={{
              height: "36px",
              padding: "0 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
              color: "#FFFFFF",
              backgroundColor: "#636AE8",
              opacity: 1,
              border: "none",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#4850E4",
              },
              "&:active": {
                backgroundColor: "#2C35E0",
              },
              "&:disabled": {
                opacity: 0.4,
              },
            }}
            onClick={handleUploadClick}
          >
            Upload
          </Button>
        </MDBox>
      </MDBox>

      {/* Data Table */}
      <DataTable
        table={{
          columns: columns,
          rows: paginatedRows.map((sensor) => ({
            ...sensor,
            view: (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  color: "black !important",
                  borderColor: "blue !important",
                  "&:hover": {
                    borderColor: "black !important",
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                  },
                }}
                onClick={() => handleViewClick(sensor.id)}
              >
                Delete
              </Button>
            ),
          })),
        }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
        noEndBorder
      />

      {/* Table Pagination */}
      <TablePagination
        component="div"
        count={sensorData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <ToastContainer />

      {/* Modal for Creating New sensor */}
      <Dialog
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
            maxWidth: "90%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle>Create sensor</DialogTitle>
        <DialogContent>
          <MDTypography sx={{ fontSize: "0.975rem" }} variant="body1" color="black">
            Sensor Name
          </MDTypography>
          <TextField
            fullWidth
            value={name}
            onChange={(e) => setname(e.target.value)}
            sx={{ marginBottom: "20px" }}
          />

          <MDTypography sx={{ fontSize: "0.975rem" }} variant="body1" color="black">
            Serial Number
          </MDTypography>
          <TextField
            fullWidth
            value={serialNo}
            onChange={(e) => setserialNo(e.target.value)}
            sx={{ marginBottom: "20px" }}
          />

          <MDTypography sx={{ fontSize: "0.975rem" }} variant="body1" color="black">
            Group
          </MDTypography>
          <FormControl fullWidth sx={{ marginBottom: "20px" }}>
            <InputLabel>Group</InputLabel>
            <Select value={group} label="Group" onChange={(e) => setGroup(e.target.value)}>
              {groupOptions.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <MDTypography sx={{ fontSize: "0.975rem" }} variant="body1" color="black">
            Status
          </MDTypography>
          <FormControl fullWidth sx={{ marginBottom: "20px" }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={status.value}
              label="Status"
              sx={{
                height: "50px",
                fontSize: "1rem",
              }}
              onChange={(e) =>
                setStatus({
                  value: e.target.value,
                  label: e.target.value === "1" ? "Active" : "Inactive",
                })
              }
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Uploading CSV */}
      <Dialog
        open={openUploadModal}
        onClose={handleCloseUploadModal}
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
            maxWidth: "90%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle>Upload CSV</DialogTitle>
        <DialogContent>
          <MDTypography sx={{ fontSize: "0.975rem" }} variant="body1" color="black">
            Upload Sensor Data CSV
          </MDTypography>
          <div {...getRootProps()} style={{ padding: "20px", border: "2px dashed #ddd" }}>
            <input {...getInputProps()} />
            <MDTypography sx={{ fontSize: "0.975rem" }} variant="body1" color="black">
              Drag and drop a file here, or click to select a file
            </MDTypography>
            {csvFileName && (
              <MDTypography sx={{ fontSize: "0.975rem" }} variant="body2" color="black">
                File: {csvFileName}
              </MDTypography>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUploadModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitCSV} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Viewing Sensor */}
      <Dialog open={openViewModal} onClose={handleCloseViewModal}>
        <DialogTitle>Are you sure you want to delete this sensor?</DialogTitle>
        <DialogContent>
          {selectedSensor && (
            <>
              <MDTypography variant="body1">Sensor Name: {selectedSensor.name}</MDTypography>
              <MDTypography variant="body1">Serial Number: {selectedSensor.serialNo}</MDTypography>
              <MDTypography variant="body1">Group: {selectedSensor.group_name}</MDTypography>
              <MDTypography variant="body1">
                Status: {getStatusLabel(selectedSensor.status)}
              </MDTypography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSensor} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default Sensors;
