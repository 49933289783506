import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Input,
} from "@mui/material";
import axios from "axios";
import API_URL from "../../../Config/config";

function CreateRun() {
  // Form state
  const [formData, setFormData] = useState({
    client_id: "",
    total_sites: "",
    product_version: "",
    country_id: "",
    pre_commission_test: false,
    LWT: false,
    LWT_count: "",
    telementry_test: false,
    telementry_count: "",
    alarm_test: false,
    alarm_count: "",
    message_delay: false,
    message_delay_count: "",
    total_sensors: false,
    total_sensors_per_unit: "",
    ping_test: false,
    auth_test: false,
    firmware_version_test: false,
    sensor_test: false,
    ping_mqtt: false,
    status: 1,
  });
  // State
  const [clients, setClients] = useState([]);
  const [productVersions, setProductVersions] = useState([]);
  const [countrie, setCountry] = useState([]);

  // Fetch clients and version from the API
  useEffect(() => {
    axios
      .get(`${API_URL}/client`)
      .then((response) => {
        setClients(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching clients:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${API_URL}/version/get-all`)
      .then((response) => {
        setProductVersions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product versions:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${API_URL}/country/get-all`)
      .then((response) => {
        setCountry(response.data);
      })
      .catch((error) => {
        console.error("Error fetching country:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);

    //  axios
    axios
      .post(`${API_URL}/runs/new-run`, formData)
      .then((response) => {
        // Show success toast message
        toast.success("Run created successfully with associated units!");

        // Clear the form after successful submission
        setFormData({
          client_id: "",
          total_sites: "",
          product_version: "",
          country_id: "",
          pre_commission_test: false,
          LWT: false,
          LWT_count: "",
          telementry_test: false,
          telementry_count: "",
          alarm_test: false,
          alarm_count: "",
          message_delay: false,
          message_delay_count: "",
          total_sensors: false,
          total_sensors_per_unit: "",
          ping_test: false,
          auth_test: false,
          firmware_version_test: false,
          sensor_test: false,
          ping_mqtt: false,
          status: 1,
        });
      })
      .catch((error) => {
        toast.error("Error creating run. Please try again.");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <MDTypography
                  variant="h6"
                  color="Black"
                  sx={{
                    marginLeft: "25px",
                    marginTop: "10px",
                  }}
                >
                  Create Run
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
                  <Grid container spacing={4}>
                    {/* Client Dropdown */}
                    <Grid item xs={3}>
                      <MDTypography sx={{ fontSize: "0.975rem" }} variant="body1" color="black">
                        Client
                      </MDTypography>
                      <FormControl
                        fullWidth
                        margin="normal"
                        sx={{ minWidth: "100px", borderRadius: "4px", borderColor: "grey.300" }}
                      >
                        <InputLabel>Select Client</InputLabel>
                        <Select
                          label="client"
                          name="client_id"
                          value={formData.client_id}
                          onChange={handleChange}
                          sx={{
                            height: "40px",
                            fontSize: "1rem",
                          }}
                        >
                          <MenuItem value="" disabled>
                            Please select...
                          </MenuItem>
                          {clients.map((client) => (
                            <MenuItem key={client.id} value={client.id}>
                              {client.company_code}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Numeric Dropdown (Sites) */}
                    <Grid item xs={3}>
                      <MDTypography sx={{ fontSize: "0.975rem" }} variant="body1" color="black">
                        No of Sites
                      </MDTypography>
                      <FormControl
                        fullWidth
                        margin="normal"
                        sx={{ minWidth: "100px", borderRadius: "4px", borderColor: "grey.300" }}
                      >
                        <InputLabel>No of Sites</InputLabel>
                        <Input
                          type="number"
                          name="total_sites"
                          value={formData.total_sites}
                          onChange={handleChange}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px",
                              fontSize: "1.2rem",
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>

                    {/* Product Version Dropdown */}
                    <Grid item xs={3}>
                      <MDTypography sx={{ fontSize: "0.975rem" }} variant="body1" color="black">
                        Product Version
                      </MDTypography>
                      <FormControl
                        fullWidth
                        margin="normal"
                        sx={{
                          marginBottom: "40px",
                          minWidth: "100px",
                          borderRadius: "4px",
                          borderColor: "grey.300",
                        }}
                      >
                        <InputLabel>Select Version</InputLabel>
                        <Select
                          label="Dropdown 3"
                          name="product_version"
                          value={formData.product_version}
                          onChange={handleChange}
                          sx={{
                            height: "40px",
                            fontSize: "1rem",
                          }}
                        >
                          <MenuItem value="" disabled>
                            Please select...
                          </MenuItem>
                          {productVersions.map((version) => (
                            <MenuItem key={version.id} value={version.id}>
                              {version.version}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Country Dropdown */}
                    <Grid item xs={3}>
                      <MDTypography sx={{ fontSize: "0.975rem" }} variant="body1" color="black">
                        Country
                      </MDTypography>
                      <FormControl
                        fullWidth
                        margin="normal"
                        sx={{ minWidth: "100px", borderRadius: "4px", borderColor: "grey.300" }}
                      >
                        <InputLabel>Select Country</InputLabel>
                        <Select
                          label="Dropdown 4"
                          name="country_id"
                          value={formData.country_id}
                          onChange={handleChange}
                          sx={{
                            height: "40px",
                            fontSize: "1rem",
                          }}
                        >
                          <MenuItem value="" disabled>
                            Please select...
                          </MenuItem>
                          {countrie.map((countries) => (
                            <MenuItem key={countries.id} value={countries.id}>
                              {countries.name}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Parent Checkboxes */}
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="pre_commission_test"
                            checked={formData.pre_commission_test}
                            onChange={handleChange}
                          />
                        }
                        label="Pre-Commission Flow"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ping_test"
                            checked={formData.ping_test}
                            onChange={handleChange}
                          />
                        }
                        label="Ping Test"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="auth_test"
                            checked={formData.auth_test}
                            onChange={handleChange}
                          />
                        }
                        label="Auth Test"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="firmware_version_test"
                            checked={formData.firmware_version_test}
                            onChange={handleChange}
                          />
                        }
                        label="Firmware Version Test"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="sensor_test"
                            checked={formData.sensor_test}
                            onChange={handleChange}
                          />
                        }
                        label="Sensor Test"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ping_mqtt"
                            checked={formData.ping_mqtt}
                            onChange={handleChange}
                          />
                        }
                        label="Ping Test(MQTT)"
                      />
                    </Grid>

                    {/* Other Input Fields and Checkboxes */}
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox name="LWT" checked={formData.LWT} onChange={handleChange} />
                        }
                        label="Enable (LWT)"
                      />

                      {/* Input field under Parent Checkbox */}
                      <FormControl
                        fullWidth
                        margin="normal"
                        sx={{ minWidth: "100px", borderRadius: "4px", borderColor: "grey.300" }}
                      >
                        <InputLabel>No of Sites</InputLabel>
                        <Input
                          type="number"
                          name="LWT_count"
                          value={formData.LWT_count}
                          onChange={handleChange}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px",
                              fontSize: "1.2rem",
                            },
                          }}
                        />
                      </FormControl>

                      {/* Message Delay Input */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="message_delay"
                            checked={formData.message_delay}
                            onChange={handleChange}
                          />
                        }
                        label="Message Delay (Mins)"
                      />
                      <FormControl
                        fullWidth
                        margin="normal"
                        sx={{ minWidth: "100px", borderRadius: "4px", borderColor: "grey.300" }}
                      >
                        <InputLabel>Mins</InputLabel>
                        <Input
                          type="number"
                          name="message_delay_count"
                          value={formData.message_delay_count}
                          onChange={handleChange}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px",
                              fontSize: "1.2rem",
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="telementry_test"
                            checked={formData.telementry_test}
                            onChange={handleChange}
                          />
                        }
                        label="Telemetry Test (per Hour/Site)"
                      />

                      <FormControl
                        fullWidth
                        margin="normal"
                        sx={{ minWidth: "100px", borderRadius: "4px", borderColor: "grey.300" }}
                      >
                        <InputLabel>Hour</InputLabel>
                        <Input
                          type="number"
                          name="telementry_count"
                          value={formData.telementry_count}
                          onChange={handleChange}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px",
                              fontSize: "1.2rem",
                            },
                          }}
                        />
                      </FormControl>

                      {/* Parent Checkbox 6 */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="total_sensors"
                            checked={formData.total_sensors}
                            onChange={handleChange}
                          />
                        }
                        label="No Of Sensors (per Unit)"
                      />
                      <FormControl
                        fullWidth
                        margin="normal"
                        sx={{ minWidth: "100px", borderRadius: "4px", borderColor: "grey.300" }}
                      >
                        <InputLabel>Units</InputLabel>
                        <Input
                          type="number"
                          name="total_sensors_per_unit"
                          value={formData.total_sensors_per_unit}
                          onChange={handleChange}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px",
                              fontSize: "1.2rem",
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>

                    {/* Parent Checkbox 4 */}
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="alarm_test"
                            checked={formData.alarm_test}
                            onChange={handleChange}
                          />
                        }
                        label="Alarm Test (per Hour/Site)"
                      />
                      <FormControl
                        fullWidth
                        margin="normal"
                        sx={{ minWidth: "100px", borderRadius: "4px", borderColor: "grey.300" }}
                      >
                        <InputLabel>Hour</InputLabel>
                        <Input
                          type="number"
                          name="alarm_count"
                          value={formData.alarm_count}
                          onChange={handleChange}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px",
                              fontSize: "1.2rem",
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Submit Button */}
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        sx={{
                          backgroundColor: "white",
                          color: "black",
                          fontSize: "1rem",
                          padding: "8px 20px",
                          "&:hover": {
                            backgroundColor: "#f1f1f1",
                            boxShadow: "none",
                          },
                          "&.MuiButton-root": {
                            backgroundColor: "white",
                            color: "black",
                            border: "1px solid #ccc",
                          },
                          marginTop: "20px",
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default CreateRun;
