import React, { useState, useEffect } from "react";

import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  TablePagination,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../examples/Tables/DataTable";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";

import API_URL from "../../../Config/config";

export const columns = [
  { Header: "Group Name", accessor: "name", align: "left" },
  {
    Header: "Status",
    accessor: "status",
    align: "center",
    Cell: ({ cell: { value } }) => getStatusLabel(value),
  },
  { Header: "Action", accessor: "view", align: "center" },
];

const statusLabelMapping = {
  1: "Active",
  0: "Inactive",
};

const getStatusLabel = (statusValue) => {
  return statusLabelMapping[statusValue] || "Unknown";
};

// Status options
const statusOptions = [
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

function Groups() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [groupData, setGroupData] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [name, setGroupName] = useState("");
  const [status, setStatus] = useState({ value: "1", label: "Active" });
  const [csvData, setCsvData] = useState(null);
  const [csvFileName, setCsvFileName] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);

  // Fetch Groups Data from API
  const fetchGroupData = () => {
    axios
      .get(`${API_URL}/site-groups/get-all-groups`)
      .then((response) => {
        setGroupData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the site-groups data:", error);
      });
  };

  useEffect(() => {
    fetchGroupData();
  }, []);

  const handleCreateClick = () => setOpenCreateModal(true);

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    setGroupName("");
    setStatus({ value: "1", label: "Active" });
    fetchGroupData();
  };

  const handleUploadClick = () => setOpenUploadModal(true);
  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    fetchGroupData();
  };

  const handleSubmit = () => {
    const newClient = {
      name: name,
      status: status.value,
    };

    // axios to create a site-groups
    axios
      .post(`${API_URL}/site-groups/new-group`, newClient)
      .then((response) => {
        console.log("New group Created:", response.data);
        setGroupName("");
        setStatus({ value: "1", label: "Active" });
        setOpenCreateModal(false);
        fetchGroupData();
        toast.success("group created successfully!");
      })
      .catch((error) => {
        toast.error("Group already exists.");
        console.error("There was an error creating the group:", error);
      });
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = groupData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop: (acceptedFiles) => handleUploadCSV(acceptedFiles),
  });

  const handleUploadCSV = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setCsvFileName(file.name);
    const formData = new FormData();
    formData.append("file", file);
    setCsvData(formData);
    setOpenUploadModal(true);
  };

  const handleSubmitCSV = () => {
    if (csvData) {
      // axios to upload CSV File
      axios
        .post(`${API_URL}/site-groups/upload`, csvData)
        .then((response) => {
          toast.success("CSV Data Submitted Successfully!");
          setOpenUploadModal(false);
          setCsvFileName(null);
          fetchGroupData();
        })
        .catch((error) => {
          console.error("Error submitting CSV:", error);
          toast.error("Groups already exists,Check the CSV file Data.");
          setCsvFileName(null);
        });
    } else {
      toast.error("No CSV data to upload.");
    }
  };

  // delete api
  const handleDeleteGroup = () => {
    if (selectedGroup) {
      axios
        .delete(`${API_URL}/site-groups/delete/${selectedGroup.id}`)
        .then((response) => {
          toast.success("Group deleted successfully!");
          setOpenViewModal(false);
          fetchGroupData();
        })
        .catch((error) => {
          console.error("Error deleting version:", error);
          toast.error("Error deleting version!");
        });
    }
  };

  const handleViewClick = (groupId) => {
    const group = groupData.find((group) => group.id === groupId);
    setSelectedGroup(group);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectedGroup(null);
  };

  return (
    <MDBox pt={3} pb={3} px={3}>
      {/* Header with buttons */}
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "20px" }}
      >
        <MDTypography variant="h4" color="Black" sx={{ flex: 1 }}>
          Groups
        </MDTypography>

        <MDBox display="flex" gap="10px" sx={{ flexShrink: 0 }}>
          <Button
            variant="contained"
            sx={{
              height: "36px",
              padding: "0 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
              color: "#FFFFFF",
              backgroundColor: "#636AE8",
              opacity: 1,
              border: "none",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#4850E4",
              },
              "&:active": {
                backgroundColor: "#2C35E0",
              },
              "&:disabled": {
                opacity: 0.4,
              },
            }}
            onClick={handleCreateClick}
          >
            Create
          </Button>
          <Button
            variant="contained"
            sx={{
              height: "36px",
              padding: "0 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
              color: "#FFFFFF",
              backgroundColor: "#636AE8",
              opacity: 1,
              border: "none",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#4850E4",
              },
              "&:active": {
                backgroundColor: "#2C35E0",
              },
              "&:disabled": {
                opacity: 0.4,
              },
            }}
            onClick={handleUploadClick}
          >
            Upload
          </Button>
        </MDBox>
      </MDBox>

      {/* Data Table */}
      <DataTable
        table={{
          columns: columns,
          rows: paginatedRows.map((group) => ({
            ...group,
            view: (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  color: "black !important",
                  borderColor: "blue !important",
                  "&:hover": {
                    borderColor: "black !important",
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                  },
                }}
                onClick={() => handleViewClick(group.id)}
              >
                Delete
              </Button>
            ),
          })),
        }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
        noEndBorder
      />
      {/* Table Pagination */}
      <TablePagination
        component="div"
        count={groupData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <ToastContainer />
      {/* Modal for Creating New site-groups */}

      <Dialog open={openCreateModal} onClose={handleCloseCreateModal}>
        <DialogTitle>Create Group</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={name}
            onChange={(e) => setGroupName(e.target.value)}
            label="Name"
            sx={{ marginBottom: "20px" }}
          />
          <FormControl fullWidth sx={{ marginBottom: "40px", minWidth: "150px" }}>
            <Select
              value={status.value}
              onChange={(e) =>
                setStatus(statusOptions.find((option) => option.value === e.target.value))
              }
              label="Status"
              defaultValue="1"
              sx={{
                height: "50px",
                fontSize: "1rem",
              }}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Uploading CSV File */}
      <Dialog open={openUploadModal} onClose={handleCloseUploadModal}>
        <DialogTitle>Upload CSV File</DialogTitle>
        <DialogContent>
          <div
            {...getRootProps()}
            style={{ border: "2px dashed #636AE8", padding: "20px", textAlign: "center" }}
          >
            <input {...getInputProps()} />
            {!csvFileName ? (
              <MDTypography variant="h6" color="black">
                Drag & Drop a CSV file here, or click to select one
              </MDTypography>
            ) : (
              <MDTypography variant="h6" color="black">
                File: {csvFileName} (Click to change)
              </MDTypography>
            )}
          </div>

          {csvFileName && (
            <div style={{ marginTop: "20px" }}>
              <MDTypography variant="h6" color="black">
                {" "}
                Click &#34;Upload&#34; to save it.
              </MDTypography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitCSV} color="primary">
            Upload
          </Button>
          <Button onClick={handleCloseUploadModal} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for delete version */}
      <Dialog open={openViewModal} onClose={handleCloseViewModal}>
        <DialogTitle>Are you sure you want to delete this Group?</DialogTitle>
        <DialogContent>
          {selectedGroup && (
            <>
              <MDTypography variant="body1"> Name: {selectedGroup.name}</MDTypography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteGroup} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default Groups;
